/* General */
$fontSize:14px;

/* Login form */
$loginBgColor:#888888;

/* Main Body */
$bodyBgColor:#ffffff;
$textColor:#333333;
$borderRadius:3px;
$transitionDuration:.2s;

/* Menu */
$scrollPanelBgColor:#ffffff;                  // Scrollbar if menu is heigher than the screen
$menuitemBadgeBgColor:#007be5;                // Optional menu item badge
$menuitemBadgeColor:#ffffff;
$submenuFontSize:13px;                        // Menu font
$menuBgColorFirst:#888888;                    // Menu Color (may be gradient)
$menuBgColorLast:#888888;
$menuitemColor:#ffffff;                       // Item (text and icons) colors
$menuitemHoverColor:#000000;
$menuitemActiveColor:#000000;
$menuitemActiveBgColor:#666666;
$menuitemBorderColor:rgba(207, 211, 224, 0.6);   // Top border of each menu entry

/* Splash screen */
$splashStartColor:#888888;
$splashFinishColor:#aaaaaa;
$splashFlashColor:#ffffff;

/* Footer */
$footerBgColor:#ffffff;

@import "_mixins";
@import "_login";
@import "_splash";
@import "_global";
@import "_wrapper";
